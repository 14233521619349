import React from 'react';

import './ErrorLayout.scss';

import { Footer } from 'app/shared/components';
import { RoutesInterface } from 'app/core/interfaces';
// import AsideLeftComponent from 'app/shared/components/app-aside-left/aside-left.component';

const ErrorLayout = (props: RoutesInterface): JSX.Element => {
  const { component: Component } = props;

  return (
    <>
      <div className="ErrorLayout">
        <div className="container">
          <div className="row">
            {/* <div className="col-3"><AsideLeftComponent /></div> */}
            <div className="col-12">
              { Component ? <Component {...props} /> : null }
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ErrorLayout;
