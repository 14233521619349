import { translate } from 'app/core';
import { Label } from 'nhsuk-react-components';
import React from 'react';

import programmeImage from 'assets/images/programme-image.jpg';
import christmasImage from 'assets/images/christmas-image.jpg';

const InformationSectionComponent = (): JSX.Element => {
  const slugTranslate = 'HOME.INFORMATION_SECTION';
  return (
    <div className="mx-4">
      <Label size="l" className='purple nhsuk-u-margin-top-8' id='information-section'>{translate(`${slugTranslate}.TITLE`)}</Label>

      <Label size="m" className='purple nhsuk-u-margin-top-8 nhsuk-u-margin-bottom-2'>{translate(`${slugTranslate}.WHAT_IS`)}</Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.WHAT_IS_INTRO`)}
      </p>
      <Label size="m" className='purple nhsuk-u-margin-top-8 nhsuk-u-margin-bottom-2'>{translate(`${slugTranslate}.WHO_IS_BEHIND`)}</Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.WHO_IS_BEHIND_ITEM_1`)}
      </p>

      <Label size="m" className='purple nhsuk-u-margin-top-8 nhsuk-u-margin-bottom-2'>{translate(`${slugTranslate}.WANT_TO_KNOW_MORE`)}</Label>
      <p className="nhsuk-body">
        {translate(`${slugTranslate}.WANT_TO_KNOW_MORE_IF_END`)}
      </p>

      <Label size="s" className='purple nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-4'>{translate(`${slugTranslate}.WANT_TO_KNOW_MORE_2_MINUTES`)}</Label>

      <video className="mx-auto nhsuk-u-margin-bottom-3" controls preload="metadata">
        <source src="/videos/Animation_01_Animatic.mp4#t=0.001" type="video/mp4" />
        {translate('NOT_SUPPORT_VIDEO')}
      </video>

      <Label size="m" className='purple nhsuk-u-margin-top-8 nhsuk-u-margin-bottom-2'>{translate(`${slugTranslate}.WHAT_PEOPLE_SAYING`)}</Label>
      <p className="nhsuk-body">
        {translate(`${slugTranslate}.WHAT_PEOPLE_SAYING_1`)}
      </p>

      <Label size="m" className='purple nhsuk-u-margin-top-8 nhsuk-u-margin-bottom-2'>{translate(`${slugTranslate}.WHAT_IS_REMISSION`)}</Label>
      <Label size="s" className='purple nhsuk-u-margin-bottom-2'>{translate(`${slugTranslate}.WHAT_IS_REMISSION_INTRO`)}</Label>
      <ol className='nhsuk-list--number nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3 nhsuk-u-margin-top-1'>
        <li className='nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-4'>{translate(`${slugTranslate}.WHAT_IS_REMISSION_LIST_1`)}</li>
        <li className='nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-4'>{translate(`${slugTranslate}.WHAT_IS_REMISSION_LIST_2`)}</li>
        <li className='nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-4'>{translate(`${slugTranslate}.WHAT_IS_REMISSION_LIST_3`)}</li>
        <li className='nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-4'>{translate(`${slugTranslate}.WHAT_IS_REMISSION_LIST_4`)}</li>
      </ol>

      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.WHAT_IS_REMISSION_ITEM_1`)}
      </p>

      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.WHAT_IS_REMISSION_ITEM_2`)}
      </p>

      <Label size="m" className='purple nhsuk-u-margin-top-8 nhsuk-u-margin-bottom-2'>{translate(`${slugTranslate}.WHAT_REMISSION_DIFFERENCE`)}</Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.WHAT_REMISSION_DIFFERENCE_ITEM_1`)}
      </p>

      <video className="mx-auto nhsuk-u-margin-bottom-3" controls preload="metadata">
        <source src="/videos/Tina_Final.mp4#t=0.001" type="video/mp4" />
        {translate('NOT_SUPPORT_VIDEO')}
      </video>

      <p className="nhsuk-body nhsuk-u-margin-bottom-3 nhsuk-u-margin-top-6">
        {translate(`${slugTranslate}.WHAT_REMISSION_DIFFERENCE_ITEM_2`)}
      </p>

      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.WHAT_REMISSION_DIFFERENCE_ITEM_2_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.WHAT_REMISSION_DIFFERENCE_ITEM_2_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.WHAT_REMISSION_DIFFERENCE_ITEM_2_LIST_3`)}</li>
        <li>{translate(`${slugTranslate}.WHAT_REMISSION_DIFFERENCE_ITEM_2_LIST_4`)}</li>
      </ul>
        
      <Label size="m" className='purple nhsuk-u-margin-top-8 nhsuk-u-margin-bottom-2'>{translate(`${slugTranslate}.AVERSA_APPROACH`)}</Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.AVERSA_APPROACH_ITEM_1`)}
      </p>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.AVERSA_APPROACH_ITEM_1_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.AVERSA_APPROACH_ITEM_1_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.AVERSA_APPROACH_ITEM_1_LIST_3`)}</li>
      </ul>

      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.AVERSA_APPROACH_ITEM_2`)}
      </p>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.AVERSA_APPROACH_ITEM_2_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.AVERSA_APPROACH_ITEM_2_LIST_2`)}</li>
      </ul>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.AVERSA_APPROACH_ITEM_3`)}
      </p>

      <Label size="m" className='purple nhsuk-u-margin-top-8 nhsuk-u-margin-bottom-2'>{translate(`${slugTranslate}.AVERSA_WHAT_YOU_GET`)}</Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.AVERSA_WHAT_YOU_GET_ITEM_1`)}
      </p>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
        <li>
          <span className="nhsuk-u-font-weight-bold purple">{translate(`${slugTranslate}.AVERSA_WHAT_YOU_GET_ITEM_1_LIST_1_A`)}</span>
          &nbsp;
          {translate(`${slugTranslate}.AVERSA_WHAT_YOU_GET_ITEM_1_LIST_1_B`)}
        </li>
        <li>
          <span className="nhsuk-u-font-weight-bold purple">{translate(`${slugTranslate}.AVERSA_WHAT_YOU_GET_ITEM_1_LIST_2_A`)}</span>
          &nbsp;
          {translate(`${slugTranslate}.AVERSA_WHAT_YOU_GET_ITEM_1_LIST_2_B`)}
        </li>
        <li>
          <span className="nhsuk-u-font-weight-bold purple">{translate(`${slugTranslate}.AVERSA_WHAT_YOU_GET_ITEM_1_LIST_3_A`)}</span>
          &nbsp;
          {translate(`${slugTranslate}.AVERSA_WHAT_YOU_GET_ITEM_1_LIST_3_B`)}
        </li>
        <li>
          <span className="nhsuk-u-font-weight-bold purple">{translate(`${slugTranslate}.AVERSA_WHAT_YOU_GET_ITEM_1_LIST_4_A`)}</span>
          &nbsp;
          {translate(`${slugTranslate}.AVERSA_WHAT_YOU_GET_ITEM_1_LIST_4_B`)}
        </li>
        <li>
          <span className="nhsuk-u-font-weight-bold purple">{translate(`${slugTranslate}.AVERSA_WHAT_YOU_GET_ITEM_1_LIST_5_A`)}</span>
          &nbsp;
          {translate(`${slugTranslate}.AVERSA_WHAT_YOU_GET_ITEM_1_LIST_5_B`)}
        </li>
        <li>
          {translate(`${slugTranslate}.AVERSA_WHAT_YOU_GET_ITEM_1_LIST_6_A`)}
          &nbsp;
          <span className="nhsuk-u-font-weight-bold purple">{translate(`${slugTranslate}.AVERSA_WHAT_YOU_GET_ITEM_1_LIST_6_B`)}</span>
          &nbsp;
          {translate(`${slugTranslate}.AVERSA_WHAT_YOU_GET_ITEM_1_LIST_6_C`)}
        </li>
      </ul>

      <Label size="m" className='purple nhsuk-u-margin-top-8 nhsuk-u-margin-bottom-2'>{translate(`${slugTranslate}.AVERSA_COST`)}</Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.AVERSA_COST_ITEM_1`)}
      </p>

      <Label size="m" className='purple nhsuk-u-margin-top-8 nhsuk-u-margin-bottom-2'>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE`)}</Label>

      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_INTRO_1`)}
      </p>
      <p className="nhsuk-body nhsuk-u-margin-bottom-8">
        {translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_INTRO_2`)}
      </p>
      {/* className="ml-2 lg:ml-1 h-20 lg:h-24 lg:py-1" */}
      <img width="70%" src={programmeImage} alt={translate('HEADER.LOGO_AVERSA')} />

      <Label size="s" className='purple nhsuk-u-margin-top-5 nhsuk-u-margin-bottom-2'>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_1`)}</Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_1_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_1_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_1_LIST_3`)}</li>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_1_LIST_4`)}</li>
      </ul>
      <Label size="s" className='purple nhsuk-u-margin-top-5 nhsuk-u-margin-bottom-2'>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_2`)}</Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_2_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_2_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_2_LIST_3`)}</li>
      </ul>
      <Label size="s" className='purple nhsuk-u-margin-top-5 nhsuk-u-margin-bottom-2'>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_3`)}</Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_3_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_3_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_3_LIST_3`)}</li>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_3_LIST_4`)}</li>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_3_LIST_5`)}</li>
      </ul>
      <Label size="s" className='purple nhsuk-u-margin-top-5 nhsuk-u-margin-bottom-2'>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_4`)}</Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_4_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_4_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_4_LIST_3`)}</li>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_4_LIST_4`)}</li>
        <li>{translate(`${slugTranslate}.AVERSA_PROGRAMME_STRUCTURE_ITEM_4_LIST_5`)}</li>
      </ul>
    </div>
  );
};

export default InformationSectionComponent;
