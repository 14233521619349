import React from 'react';

import './BaseLayout.scss';

import { Footer, Header } from 'app/shared/components';
import { RoutesInterface } from 'app/core/interfaces';

const BaseLayout = (props: RoutesInterface): JSX.Element | null => {
  const { component: Component } = props;

  return (
    <>
      <Header />
      <div className="BaseLayout">
        <main role="main" className="main">
          { Component ? <Component {...props} /> : null }
        </main>
      </div>
      <Footer />
    </>
  );
};
export default BaseLayout;
