export interface Environment {
  development: boolean;
  apiUrl: string;
  imagesUrl: string;
  useMocks: boolean;
  secretKeyBase: string;
  changingHealthToken: string;
  unitId: number;
  packageId: number;
  chUrl: string;
}

interface Environments {
  dev: Environment;
  staging: Environment;
  prod: Environment;
  secretKeyBase?: string;
  changingHealthToken?: string;
  unitId?: number;
  packageId?: number;
}

const environment: Environments = {
  dev: {
    development: true,
    apiUrl: 'https://api.changinghealth.com/api/ext/user',
    useMocks: false,
    secretKeyBase: '',
    changingHealthToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0IiwiaWF0IjoxNjIwMjA0Mzc3LCJleHAiOjE5MzU1NjQzNzcsIm5iZiI6MTYyMDIwNDM3NywianRpIjoiSHJkb3RqbEdSYXVZRklCbCIsImFwcF9pZCI6IjE5IiwiaXAiOltdfQ.s0eYIA_7F_jRKFeq-0PU1kg8HscaWiq0N5wZzgWvv20',
    unitId: 54,
    packageId: 95,
    chUrl: 'https://app.dev.changinghealth.com',
  } as Environment,
  staging: {
    development: false,
    apiUrl: 'https://api.changinghealth.com/api/ext/user',
    useMocks: false,
    secretKeyBase: '',
    changingHealthToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0IiwiaWF0IjoxNjIwMjA0Mzc3LCJleHAiOjE5MzU1NjQzNzcsIm5iZiI6MTYyMDIwNDM3NywianRpIjoiSHJkb3RqbEdSYXVZRklCbCIsImFwcF9pZCI6IjE5IiwiaXAiOltdfQ.s0eYIA_7F_jRKFeq-0PU1kg8HscaWiq0N5wZzgWvv20',
    unitId: 54,
    packageId: 95,
    chUrl: 'https://app.stg.changinghealth.com',
  } as Environment,
  prod: {
    development: false,
    apiUrl: 'https://api.changinghealth.com/api/ext/user',
    useMocks: false,
    secretKeyBase: '',
    changingHealthToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vbG9jYWxob3N0IiwiaWF0IjoxNjIwMjA0Mzc3LCJleHAiOjE5MzU1NjQzNzcsIm5iZiI6MTYyMDIwNDM3NywianRpIjoiSHJkb3RqbEdSYXVZRklCbCIsImFwcF9pZCI6IjE5IiwiaXAiOltdfQ.s0eYIA_7F_jRKFeq-0PU1kg8HscaWiq0N5wZzgWvv20',
    unitId: 54,
    packageId: 95,
    chUrl: 'https://app.changinghealth.com',
  } as Environment,
};

export default environment;
