import { translate } from 'app/core';
import { Label } from 'nhsuk-react-components';
import React from 'react';

const WhoProgrammeForSection = (): JSX.Element => {
  const slugTranslate = 'HOME.WHO_PROGRAMME_FOR';
  return (
    <div className="mx-4">
      <Label size="l" className='purple nhsuk-u-margin-top-8' id='who-programme-for-section'>{translate(`${slugTranslate}.TITLE`)}</Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_1`)}
      </p>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_2`)}
      </p>

      <Label size="s" className='purple nhsuk-u-margin-top-5 nhsuk-u-margin-bottom-1'>{translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_3`)}</Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_3_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_3_LIST_2`)}</li>
      </ul>

      <Label size="s" className='purple nhsuk-u-margin-top-5 nhsuk-u-margin-bottom-1'>{translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_4`)}</Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_4_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_4_LIST_2`)}</li>
      </ul>

      <Label size="s" className='purple nhsuk-u-margin-top-5 nhsuk-u-margin-bottom-1'>{translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_5`)}</Label>
      <p className="nhsuk-body">
        {translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_5_1`)}
      </p>

      <Label size="s" className='purple nhsuk-u-margin-top-5 nhsuk-u-margin-bottom-1'>{translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_6`)}</Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_6_1`)}
      </p>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_6_1_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_6_1_LIST_2`)}</li>
      </ul>

      <Label size="s" className='purple nhsuk-u-margin-top-5 nhsuk-u-margin-bottom-1'>{translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_7`)}</Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_7_1`)}
      </p>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_7_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.WHO_PROGRAMME_FOR_ITEM_7_LIST_2`)}</li>
      </ul>

      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.WHO_PROGRAMME_FOR_END`)}
      </p>

      <Label size="m" className='purple nhsuk-u-margin-top-8 nhsuk-u-margin-bottom-1'>{translate(`${slugTranslate}.WHO_IS_NOT_FOR`)}</Label>
      <p className="nhsuk-body">
        {translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_1`)}
      </p>

      <Label size="s" className='purple nhsuk-u-margin-top-5 nhsuk-u-margin-bottom-1'>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_2`)}</Label>
      <p className="nhsuk-body">
        {translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_2_1`)}
      </p>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_2_1_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_2_1_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_2_1_LIST_3`)}</li>
        <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_2_1_LIST_4`)}</li>
      </ul>

      <Label size="s" className='purple nhsuk-u-margin-top-5 nhsuk-u-margin-bottom-1'>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_3`)}</Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_3_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_3_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_3_LIST_3`)}</li>
        <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_3_LIST_4`)}</li>
        <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_3_LIST_5`)}</li>
        <li>
          {translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_3_LIST_6`)}
          <ul className='nhsuk-list--bullet nhsuk-u-margin-top-2 nhsuk-u-margin-left-3'>
            <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_3_LIST_6_LIST_1`)}</li>
            <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_3_LIST_6_LIST_2`)}</li>
            <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_3_LIST_6_LIST_3`)}</li>
          </ul>
        </li>
        <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_3_LIST_7`)}</li>
        <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_3_LIST_8`)}</li>
        <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_3_LIST_9`)}</li>
        <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_3_LIST_10`)}</li>
      </ul>
      <p className="nhsuk-body">
        {translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_3_END`)}
      </p>

      <Label size="s" className='purple nhsuk-u-margin-top-5 nhsuk-u-margin-bottom-1'>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_4`)}</Label>
      <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
        <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_4_LIST_1`)}</li>
        <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_4_LIST_2`)}</li>
        <li>{translate(`${slugTranslate}.WHO_IS_NOT_FOR_ITEM_4_LIST_3`)}</li>
      </ul>

      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.WHO_IS_NOT_FOR_END`)}
      </p>
    </div>
  );
};

export default WhoProgrammeForSection;
