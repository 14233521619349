import React from 'react';
import { translate } from 'app/core';
import configuration from 'config';
import ButtonComponent from '../Button/Button';

interface ButtonLoginPropsI {
  onClick?: CallableFunction;
  classNameContainer?: string;
  className?: string;
}

const ButtonLoginComponent = (props: ButtonLoginPropsI): JSX.Element => {
  const { onClick, classNameContainer, className } = props;
  const { chUrl } = configuration;

  return (
    <a href={chUrl} className={classNameContainer}>
      <ButtonComponent text={translate('HOME.LOGIN')} className={className} onClick={onClick} />
    </a>
  );
};

export default ButtonLoginComponent;
