import React from 'react';

import './P404.component.scss';

interface P404Props{
  text?: string;
}

const P404Component = (props: P404Props): JSX.Element => {
  const { text } = props;

  return (
    <>
      <h1>404 Not Found</h1>
      <h3>
        No match for 
        {' '}
        { text }
        {' '}
        <code>{window.location.pathname}</code>
      </h3>
    </>
  );
};

export default P404Component;
