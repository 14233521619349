const translations = {
  EMAIL_AVERSA: 'aversa@support.changinghealth.com',
  NOT_SUPPORT_VIDEO: 'Your browser does not support the video tag.',
  FORM: {
    PLEASE_SELECT: 'Please Select',
    PLEASE_CORRECT_FORM_ERRORS: 'Please correct the form errors'
  },
  HOME: {
    CLICK: 'Click Me',
    LOGIN: 'Login',
    ALREADY_REGISTERED: 'Already registered?',
    LOGIN_TO_AVERSA: 'Login to Aversa by clicking the button below.',
    YOU_WILL_NEED: 'You will need your username and password to access the programme.',
    WANT_TO_REGISTER: 'Want to register for Aversa?',
    WANT_TO_REGISTER_CONTACT_GP: 'Follow the instructions in the text message invite you received from your GP practice to request a referral.',
    BANNER: 'Working together towards a strong and independent future',
    TITLE: 'Welcome to the Aversa Diabetes information page',
    DESCRIPTION: 'If you’ve been advised by your GP that you are eligible to join the Aversa Diabetes programme, the information on this page  will help you:',
    DESCRIPTION_LIST_1: 'decide whether it’s something you’d like to take part in',
    DESCRIPTION_LIST_2: 'check if the programme is suitable for you',
    DESCRIPTION_LIST_3: 'find out what next steps to take, if you would like to sign up',
    INFORMATION_SECTION_TAB: 'About Aversa',
    QUESTIONS_SECTION_TAB: 'Frequently asked questions',
    CAN_TAKE_SECTION_TAB: 'Who can take part?',
    PATIENT_INFORMATION_TAB: 'Patient information',
    INFORMATION_SECTION: {
      TITLE: 'About Aversa',
      WHAT_IS: 'What is Aversa Diabetes?',
      WHAT_IS_INTRO: 'Aversa is a new, entirely different way of managing diabetes. Aversa means \'reverse\' in Latin. Recent research has shown that people who have a fairly recent diagnosis of type 2 diabetes can reverse some of its\' underlying causes by following a three step process. The one year Aversa programme takes people through this process.',
      WHO_IS_BEHIND: 'Who is behind the Aversa programme?',
      WHO_IS_BEHIND_ITEM_1: 'Changing Health is a provider of personalised and evidence-based digital education programmes for people with or at risk of developing type 2 diabetes. We manage and reverse chronic conditions through national NHS contracts and pilot programme development. Changing Health is delivering the Aversa programme in partnership with Abbott Nutrition, a market leader in diabetes nutrition.',
      CHRISTMAS_IS_COMING: 'Christmas is coming',
      CHRISTMAS_IS_COMING_NEW: 'NEW: Option to sign up now and start Aversa in January 2023',
      CHRISTMAS_IS_COMING_ITEM_1: 'We recognise that starting the low calorie diet just as the festive season begins may not be the ideal time for everybody. So if you accept a place on Aversa before Christmas, you will have the option to delay starting the programme until early January.',
      CHRISTMAS_IS_COMING_ITEM_2: 'Demand for the programme is likely to be high in the New Year, so we suggest contacting your practice before Christmas to request a referral and secure your place.',
      WANT_TO_KNOW_MORE: 'Want to know more?',
      WANT_TO_KNOW_MORE_IF_END: 'Watch the short video below to find out about the Aversa programme.',
      WANT_TO_KNOW_MORE_2_MINUTES: 'Aversa in 2 minutes',
      WHAT_PEOPLE_SAYING: 'What are people saying about Aversa?',
      WHAT_PEOPLE_SAYING_1: 'Aversa.... When i first heard about this i was sceptical to say the least and a little scared! Saying it\'s easy is not true, however once you see the results, it gets easier as you want them to continue. 7 weeks in now and I am really enjoying and feel better physically and mentally. Don\'t be afraid to try that is my advice. It works!',
      WHAT_IS_REMISSION: 'What is diabetes remission?',
      WHAT_IS_REMISSION_INTRO: '4 key facts',
      WHAT_IS_REMISSION_LIST_1: 'Remission means restoring the body’s blood glucose (blood sugar) control so that levels stay below the diabetes threshold without needing glucose-lowering medication.',
      WHAT_IS_REMISSION_LIST_2: 'Remission only applies to type 2, not other types of diabetes.',
      WHAT_IS_REMISSION_LIST_3: 'Weight loss of 10 to 15kg (between one and a half to two and a half stones) can put type 2 diabetes into remission for three quarters of people whose diabetes has been diagnosed within the last 6 years.',
      WHAT_IS_REMISSION_LIST_4: 'Remission isn\'t the same as a permanent cure. Ongoing remission requires support to maintain positive lifestyle changes, keep harmful fat out of body organs, limit weight gain and help reset things when needed.',
      WHAT_IS_REMISSION_ITEM_1: 'Achieving the amount of weight loss needed for remission requires a more intensive approach than is usually used in type 2 diabetes care.',
      WHAT_IS_REMISSION_ITEM_2: 'That\'s why Changing Health has developed the Aversa programme.',
      WHAT_REMISSION_DIFFERENCE: 'What difference does remission make?',
      WHAT_REMISSION_DIFFERENCE_ITEM_1: 'Watch Tina’s story (just over 2 minutes) to find out what putting type 2 diabetes into remission meant to her:',
      WHAT_REMISSION_DIFFERENCE_ITEM_2: 'There are other positives to taking part in Aversa even if remission doesn\'t happen, including:',
      WHAT_REMISSION_DIFFERENCE_ITEM_2_LIST_1: 'Lower blood glucose and blood pressure levels and reduced need to take diabetes and blood pressure medications',
      WHAT_REMISSION_DIFFERENCE_ITEM_2_LIST_2: 'The many other health benefits of reduced body fat, including reducing the risk of heart disease, stroke and weight-related cancers.',
      WHAT_REMISSION_DIFFERENCE_ITEM_2_LIST_3: 'Weight loss is the most effective treatment for fatty liver disease caused by higher amounts of body fat (NAFLD).',
      WHAT_REMISSION_DIFFERENCE_ITEM_2_LIST_4: 'Improvements in everyday life, seemingly simple things like putting on socks and shoes more easily, climbing the stairs without getting so out of breath, and/or keeping up with the kids or grandkids. These \'little things\' really do mean a lot.',
      AVERSA_APPROACH: 'The Aversa approach',
      AVERSA_APPROACH_ITEM_1: 'Aversa offers',
      AVERSA_APPROACH_ITEM_1_LIST_1: 'Tailored journeys guided by your preferences and choices.',
      AVERSA_APPROACH_ITEM_1_LIST_2: 'A flexible approach to making sustainable lifestyle changes that improve health and wellbeing.',
      AVERSA_APPROACH_ITEM_1_LIST_3: 'Support to find a balance between managing diabetes and having a \'normal\' life.',
      AVERSA_APPROACH_ITEM_2: 'Although remission is linked to weight loss, Aversa doesn’t focus on weight reduction as a goal in itself.',
      AVERSA_APPROACH_ITEM_2_LIST_1: 'We believe weight loss is a side effect that happens when you make positive changes.',
      AVERSA_APPROACH_ITEM_2_LIST_2: 'We provide the practical and emotional support that will help you achieve those changes.',
      AVERSA_APPROACH_ITEM_3: 'We keep things light hearted - lectures are strictly prohibited!',
      AVERSA_WHAT_YOU_GET: 'What you get when you sign up to Aversa',
      AVERSA_WHAT_YOU_GET_ITEM_1: 'Aversa is not another app. It’s an online course, with weekly online content. The course provides:',
      AVERSA_WHAT_YOU_GET_ITEM_1_LIST_1_A: 'A full 12 months of support',
      AVERSA_WHAT_YOU_GET_ITEM_1_LIST_1_B: 'to develop and sustain positive diet, activity and sleep habits that suit your lifestyle',
      AVERSA_WHAT_YOU_GET_ITEM_1_LIST_2_A: 'Online content',
      AVERSA_WHAT_YOU_GET_ITEM_1_LIST_2_B: '- a mixture of articles, animations, quizzes and challenges to build your knowledge and skills over time.',
      AVERSA_WHAT_YOU_GET_ITEM_1_LIST_3_A: 'One to one telephone support from a dedicated lifestyle coach',
      AVERSA_WHAT_YOU_GET_ITEM_1_LIST_3_B: 'to help you achieve your goals, overcome barriers and stay on track.',
      AVERSA_WHAT_YOU_GET_ITEM_1_LIST_4_A: 'Meal replacement shakes',
      AVERSA_WHAT_YOU_GET_ITEM_1_LIST_4_B: 'for the initial weight loss phase, provided and delivered by courier to your door free of charge.',
      AVERSA_WHAT_YOU_GET_ITEM_1_LIST_5_A: 'Recipe and meal plan suggestions',
      AVERSA_WHAT_YOU_GET_ITEM_1_LIST_5_B: 'to accompany your chosen eating plan in each programme phase.',
      AVERSA_WHAT_YOU_GET_ITEM_1_LIST_6_A: 'Invitations to join',
      AVERSA_WHAT_YOU_GET_ITEM_1_LIST_6_B: 'optional online group calls',
      AVERSA_WHAT_YOU_GET_ITEM_1_LIST_6_C: 'with a coach and other Aversa participants at key points in the programme where extra support is often helpful.',
      AVERSA_COST: 'Is there a cost?',
      AVERSA_COST_ITEM_1: 'The programme, including meal replacement shakes, is completely free of charge. The results will be evaluated at the end of the programme to see if it’s something that should be made available more widely.',
      AVERSA_PROGRAMME_STRUCTURE: 'The Aversa programme structure',
      AVERSA_PROGRAMME_STRUCTURE_INTRO_1: 'Aversa is carefully-designed as a series of phases that build on each other over time. Each phase has a specific purpose, so you\'ll focus on different things in each one.',
      AVERSA_PROGRAMME_STRUCTURE_INTRO_2: 'Because Aversa is online, you can fit the weekly content around whatever else you have going on - it will usually take no more than around 30 minutes to complete each week. The time involved reduces after the first six months.',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_1: 'Get Set',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_1_LIST_1: 'Programme and coach introductions',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_1_LIST_2: 'Understanding remission',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_1_LIST_3: 'Selecting your low calorie diet plan for Phase 1',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_1_LIST_4: 'Preparing for your low calorie plan to maximise your chances of success',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_2: 'Phase 1: Low calorie diet (800-1000 calories per day)',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_2_LIST_1: 'Standard plan: 4 vanilla meal replacement shakes plus 2 small meals of non starchy vegetables per day. This has the best evidence for diabetes remission.',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_2_LIST_2: 'Other plans offer a varied ratio of meal replacement shakes and real food.',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_2_LIST_3: 'Support to understand individual triggers for eating that can be barriers to long-term success',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_3: 'Phase 2: Transition from the low calorie diet back to normal eating',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_3_LIST_1: 'A gradual process, focusing on one meal at a time',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_3_LIST_2: 'Guidance on meal balance, portion size and helpful food choices',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_3_LIST_3: 'Reintroducing favourite meals, considering snacking and alcohol (if relevant)',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_3_LIST_4: 'Activity challenges',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_3_LIST_5: 'Remission status explained',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_4: 'Phase 3: Maintenance',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_4_LIST_1: 'Experiment to find a maintenance strategy to suit you',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_4_LIST_2: 'Choice of eating plans with evidence of benefit for type 2 diabetes',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_4_LIST_3: 'Setting and achieving meaningful goals',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_4_LIST_4: 'Aversa 21 day challenges - our way of helping you develop useful habits by trying them out before you commit',
      AVERSA_PROGRAMME_STRUCTURE_ITEM_4_LIST_5: 'Optional weight reset plans'
    },
    QUESTIONS_SECTION: {
      TITLE: 'Frequently asked questions',
      BACK_TO_TOP: 'Back to top',

      QUESTION_1: 'Can I sign up to Aversa if I have not received an invitation?',
      QUESTION_1_ITEM_1: 'Not at this time. Aversa Diabetes is available in selected areas and GP practices.',
      QUESTION_1_ITEM_2: 'To take part you must have received an invitation from your GP practice. Text message invites will be sent out to people who live in the selected areas and meet the main inclusion criteria.',
      QUESTION_1_ITEM_3: 'The programme may become more widely available in future.',

      QUESTION_2: 'How manageable is a diet of 800 to 1000 calories a day?',
      QUESTION_2_ITEM_1: 'Very manageable - with appropriate medical supervision, preparation and support. That\'s not to say it\'s not a challenge, it does require commitment and it takes a little while to get used to, but it\'s very do-able (and only for a short time).',
      QUESTION_2_ITEM_2: 'Diets of between 800 and 1000 calories per day have been used in hundreds of research studies. While there are some potential side effects to be aware of (see question 4 of the FAQs), these diets are safe and effective for weight loss and diabetes remission.',
      QUESTION_2_ITEM_3: 'You will receive a dietitian approved plan that provides adequate nutrition while maintaining the low calorie range.',

      QUESTION_3: 'Is rapid weight loss sustainable and safe?',
      QUESTION_3_LIST_1: 'The commonly held belief that losing weight slowly is the best way to keep it off is not supported by research in this area. ',
      QUESTION_3_LIST_2: 'Losing weight more slowly  makes no difference to the amount of weight  regained afterwards.',
      QUESTION_3_LIST_3: 'In fact, the more weight that’s lost within the first 6 months of a weight loss programme, the more weight is generally kept off further down the line.',
      QUESTION_3_ITEM_1: 'Low calorie diets on their own will not be a standalone solution for weight loss. There is no ‘quick fix’. They should always be part of a longer-term programme which provides ongoing support to adopt healthy eating and activity habits afterwards and to maintain the effects of the diet through long term lifestyle change. The same is true for any weight loss intervention.',
      QUESTION_3_ITEM_2: 'For any weight loss to be sustained there needs to be a commitment to make long term changes to eating and activity habits, an understanding that there will be ups and downs along the way, and a determination to persevere. If you make the initial commitment, Aversa will help you develop the rest.',

      QUESTION_4: 'What are the potential side effects of following a low calorie diet of 800 to 1000 calories a day? ',
      QUESTION_4_LIST_1: 'The side effects of a low calorie diet are usually not troublesome enough to prevent people from continuing the diet.',
      QUESTION_4_LIST_2: 'Side effects in the early stages of the low calorie diet include hunger, irritability, feeling tired, headaches and sometimes a bit dizzy.',
      QUESTION_4_LIST_3: 'Not everyone experiences side effects, and they usually settle down after the first week or two.',
      QUESTION_4_LIST_4: 'Other side effects can include constipation or diarrhoea, nausea and feeling the cold more acutely.',
      QUESTION_4_LIST_5: 'With any significant weight loss there is a small risk of problematic gallstones or gout, especially for people who have had them before. Speak to your GP if you have any concerns about this.',
      QUESTION_4_LIST_6: 'After a low calorie diet a small number of people experience some transient hair thinning/hair loss, which resolves over a few months.',
      QUESTION_4_ITEM_1: 'Your GP will continue to oversee your medical care while you are following the low calorie diet.',

      QUESTION_5: 'Are meal replacement drinks suitable for people who have type 2 diabetes?',
      QUESTION_5_ITEM_1: 'The vanilla flavour nutrition drinks are made by Abbott nutrition, a pharmaceutical company specialising in nutrition products. They contain enough protein to replace a meal and are fortified with 28 vitamins and minerals. The milk-based drinks are used as a partial meal replacement alongside real foods (non starchy vegetables) in the Aversa programme. For allergen information, the drinks contain both milk proteins and soy. They are suitable for lacto-vegetarians (vegetarians who drink milk). The products are not suitable for people following a vegan lifestyle.',
      QUESTION_5_ITEM_2: 'The drinks contain some sugar in addition to sweeteners.The amount of protein in the drinks reduces the impact it has on blood glucose levels, so it’s not the same as drinking sugary soft drinks. The total amount of carbohydrate is similar to one slice of bread (21g). Diets using meal replacement products have the strongest evidence base for non-surgical diabetes remission. They are an effective means to an end and overall they form a short part of the one year programme. It is the dietary habits that come after the low calorie diet that determine long term success.',

      QUESTION_6: 'Will I be able to stop any of my medications?',
      QUESTION_6_ITEM_1: 'Diabetes and sometimes blood pressure medications need to be reduced or stopped when you start the low calorie diet. If you take medications for other conditions they may also need to be adjusted.',
      QUESTION_6_ITEM_2: 'Your GP will give you written instructions on what changes to make when they refer you to the programme.',
      QUESTION_6_ITEM_3_A: 'You should not make the changes until you are advised to do so at the appropriate point in the programme.',
      QUESTION_6_ITEM_3_B: 'This will be on the first day of the low calorie diet. Keep taking your medications as normal until then.',

      QUESTION_7: 'Should I still attend my diabetes reviews when I’m on the programme?',
      QUESTION_7_ITEM_1: 'The Aversa programme is in addition to your usual medical care.You should continue to attend your routine diabetes reviews when invited.',
      QUESTION_7_ITEM_2: 'Medical supervision remains under the care of your GP practice during the programme. You will be prompted to book a GP review at 6 and 12 months after starting the programme. You should book and attend these appointments so that your GP can monitor your blood glucose and blood pressure levels and adjust your medications if needed.',

      QUESTION_8: 'Will my diabetes go into remission?',
      QUESTION_8_ITEM_1: 'In a research study called the Diabetes Remission Clinical Trial (DiRECT), just under half of people who had been diagnosed with type 2 diabetes within the last 6 years had diabetes in remission after one year. The main factor predicting diabetes remission was greater weight loss and less weight regain. Diabetes was in remission for three quarters of people who sustained a weight loss of at least 1.5 stones (10kg)',
      QUESTION_8_ITEM_2: 'There are no guarantees of remission, but the likelihood of type 2 diabetes remission is much higher with programmes such as Aversa compared to standard diabetes advice.',
      QUESTION_8_ITEM_3: 'After the low calorie diet you’ll have a blood test at your GP practice to see if your diabetes has gone into remission. The Aversa Diabetes programme will help you understand your own body’s response to the low calorie diet and provide options that have proven benefit for diabetes, whether or not it goes into remission.',

      QUESTION_9: 'How flexible is the diet at each stage?',
      QUESTION_9_ITEM_1: 'Although there is some flexibility in the ratio of shakes to real food, the low calorie diet is intentionally quite prescriptive, to reduce decisions about what and how much to eat. People who\'ve done it say they like how easy it is to follow, and that reducing too much variety in the 12 weeks of Phase 1 is helpful, and part of the reason it\'s so effective.',
      QUESTION_9_ITEM_2: 'Phases 2 and 3 are much more flexible. Phase 2 helps you reintroduce and modify your favourite meals and make decisions about extras such as snacks and alcohol. Phase 3 incorporates a Mediterranean style of eating, adaptable to any culture or cuisine.',
      QUESTION_9_ITEM_3: 'Further on in the programme you\'ll have the option to add or change to different eating plans that focus on the type and amount of carbohydrate you eat (Smart Carbs plan) and then the timing of your meals each day (Time Restricted Eating plan).',
      QUESTION_9_ITEM_4: 'We\'ll help you decide on the best option for you at each stage, and if something doesn\'t work for you after giving it a try, you always have the option to change.',

      QUESTION_10: 'How long will it take to get started on the programme?',
      QUESTION_10_ITEM_1: 'Please be aware that there may be a few weeks to wait for your referral appointment and then to make the necessary check before starting the programme. Please be patient. If you\'ve received an invitation, your GP practice has opted to be part of the Aversa programme and is aware that a referral appointment and checks will be required.',
      QUESTION_10_ITEM_2: 'Your referral form will be sent to Changing Health via secure email. Once approved, all eligible participants will receive a welcome email with information about the programme start and an invitation to join an Aversa kick-off call with one of our coaches..',
      QUESTION_10_ITEM_3: 'It will help us speed things along if we know in advance whether you would prefer a morning, afternoon or early evening call to start the programme. We’ve requested this information on the referral form.',

      QUESTION_11: 'What if I’m going on holiday, spending time abroad or have a special occasion coming up during the programme?\n',
      QUESTION_11_ITEM_1: 'Consider if this is going to be the right time for you to commit to a programme like this.',
      QUESTION_11_ITEM_2: 'The meal replacement shakes can only be delivered to a UK address. If you are out of the UK during the 12 week low calorie diet you will be unable to follow the plan during this time. This does not necessarily exclude you from participating, but you should factor in any holidays booked when deciding whether to participate.',
      QUESTION_11_ITEM_3: 'Everyone has special occasions in their life, and we will help you decide how you would like to manage these during your time on the programme.',

      WHAT_SHOULD_DO_NEXT: 'What should I do next?',
      WHAT_SHOULD_DO_NEXT_START: 'There are three important steps:',
      WHAT_SHOULD_DO_NEXT_ITEM_1: 'Have a think and talk to those close to you to help you decide if you would like to take part in the Aversa programme.',
      WHAT_SHOULD_DO_NEXT_ITEM_1_LIST_1: 'Show them the information on this page.',
      WHAT_SHOULD_DO_NEXT_ITEM_1_LIST_2: 'Consider the benefits outlined above, along with any challenges or downsides that might be involved. Which seems more important to you right now (there\'s no right or wrong answer to this).',
      WHAT_SHOULD_DO_NEXT_ITEM_1_LIST_3: 'You’ll get lots of support from the programme, but having support from those around you is very helpful when undertaking this type of programme.',
      WHAT_SHOULD_DO_NEXT_ITEM_2: 'Ask questions.',
      WHAT_SHOULD_DO_NEXT_ITEM_2_LIST_1: 'Please take time to read all of the information on this page which may help answer your questions',
      WHAT_SHOULD_DO_NEXT_ITEM_2_LIST_2: 'You can email Alison (Aversa’s lead dietitian) with any questions or to arrange a call to discuss the programme, at:',
      WHAT_SHOULD_DO_NEXT_ITEM_3: 'Decide',
      WHAT_SHOULD_DO_NEXT_ITEM_3_LIST_1: 'If you decide you would like to take part, follow the instructions in the text message invitation from your GP practice to start the Aversa referral process.',
      WHAT_SHOULD_DO_NEXT_ITEM_3_LIST_1_LIST_1: 'Your GP, practice nurse or other practitioner will check that you are eligible for the programme',
      WHAT_SHOULD_DO_NEXT_ITEM_3_LIST_1_LIST_2: 'They will advise you about any medication changes you will need to make once you start.',
      WHAT_SHOULD_DO_NEXT_ITEM_3_LIST_1_LIST_3: 'Depending on when you last had them done you might need to have up-to-date measurement of your weight, blood pressure or blood glucose levels (HbA1c blood test) taken.',
      WHAT_SHOULD_DO_NEXT_ITEM_4: 'If you decide you do not want to take part, you don’t need to do anything. Thanks for taking the time to consider the programme. If you change your mind at a later date, come back and follow the steps above.',
      WHAT_SHOULD_DO_NEXT_ITEM_5: 'As this is currently a pilot programme we cannot guarantee that places will still be available at a future date.',
    },
    WHO_PROGRAMME_FOR: {
     TITLE: 'Who is the programme for?',
      WHO_PROGRAMME_FOR_ITEM_1: 'Aversa Diabetes is available in selected areas and GP practices.',
      WHO_PROGRAMME_FOR_ITEM_2: 'To take part you must have received an invitation from your GP practice. These will be sent out to people who live in the selected areas and meet the main inclusion criteria. Which are:',
      WHO_PROGRAMME_FOR_ITEM_3: '1. You\'ve had a diagnosis of Type 2 diabetes within the last 6 years',
      WHO_PROGRAMME_FOR_ITEM_3_LIST_1: 'Aversa is only appropriate for type 2 diabetes. Other types of diabetes don\'t respond in the same way.',
      WHO_PROGRAMME_FOR_ITEM_3_LIST_2: 'It\'s not currently available for type 2 diabetes diagnosed over 6 years ago, as the response is more varied with longer duration diabetes.',
      WHO_PROGRAMME_FOR_ITEM_4: '2. You\'re able to access the digital programme, and read and understand English',
      WHO_PROGRAMME_FOR_ITEM_4_LIST_1: 'You\'ll need access to the internet and a computer, smartphone or tablet',
      WHO_PROGRAMME_FOR_ITEM_4_LIST_2: 'The programme is only currently available in English',
      WHO_PROGRAMME_FOR_ITEM_5: '3. You\'re aged between 20 and 70 years old on the date you received the invitation from your GP practice',
      WHO_PROGRAMME_FOR_ITEM_5_1: 'The upper age limit is in line with research studies and other NHS programmes using low calorie diets for diabetes remission.',
      WHO_PROGRAMME_FOR_ITEM_6: '4. Your HbA1c (blood glucose test taken at your diabetes review appointments) is above a certain range',
      WHO_PROGRAMME_FOR_ITEM_6_1: 'The programme is currently being offered to people who are most likely to improve their health risk if their blood glucose (blood sugar) levels are reduced. It\'s available',
      WHO_PROGRAMME_FOR_ITEM_6_1_LIST_1: 'If your most recent HbA1c test result was in the diabetes range (48 or above) and you are not prescribed any medication to help lower your blood glucose levels (sometimes called \'diet controlled\' diabetes)',
      WHO_PROGRAMME_FOR_ITEM_6_1_LIST_2: 'If you are prescribed medication(s) to help lower your blood glucose levels and your most recent HbA1c test result was 42 or above.',
      WHO_PROGRAMME_FOR_ITEM_7: '5. The programme is appropriate for your Body Mass Index (BMI)',
      WHO_PROGRAMME_FOR_ITEM_7_1: 'The low calorie diet results in significant weight loss. This can be recommended if',
      WHO_PROGRAMME_FOR_ITEM_7_LIST_1: 'You\'re from a non-White ethnic background and have a BMI between 25 and 45 kg/㎡',
      WHO_PROGRAMME_FOR_ITEM_7_LIST_2: 'You\'re from a White ethnic background and have a BMI between 27 and 45 kg/㎡',
      WHO_PROGRAMME_FOR_END: 'This reflects a higher risk of health problems like type 2 diabetes at lower levels of BMI in some ethnic groups.',
      WHO_IS_NOT_FOR: 'Who is the programme not suitable for?',
      WHO_IS_NOT_FOR_ITEM_1: 'The following criteria are programme exclusions. The Aversa programme will not be a suitable option for you if one or more of the following apply:',
      WHO_IS_NOT_FOR_ITEM_2: '1.  Certain medications are not compatible with the programme and would usually need to be stopped. Sometimes it’s best to stay on the medication for health reasons.',
      WHO_IS_NOT_FOR_ITEM_2_1: 'That means it’s not possible to do the Aversa programme if you take:',
      WHO_IS_NOT_FOR_ITEM_2_1_LIST_1: 'Insulin',
      WHO_IS_NOT_FOR_ITEM_2_1_LIST_2: 'Three or more types of medications (tablets or injectables) to help lower your blood glucose levels.',
      WHO_IS_NOT_FOR_ITEM_2_1_LIST_3: 'If your doctor has prescribed a certain type of diabetes medication, known as an SGLT2 inhibitor, specifically to protect your kidneys or heart.',
      WHO_IS_NOT_FOR_ITEM_2_1_LIST_4: 'If you are taking Orlistat (Xenical) or Alli (over the counter) to aid weight loss. You can choose to stop this medication if you would like to join the Aversa programme. Discuss with your healthcare team if it has been prescribed.',
      WHO_IS_NOT_FOR_ITEM_3: '2. The intensive dietary requirements of the first phase of the Aversa programme will usually improve health and wellbeing. However, sometimes this type of diet is not recommended. Aversa is not right for you if you have any of the following physical or mental conditions:',
      WHO_IS_NOT_FOR_ITEM_3_LIST_1: 'An active drug or alcohol dependency.',
      WHO_IS_NOT_FOR_ITEM_3_LIST_2: 'An eating disorder or a risk of one.',
      WHO_IS_NOT_FOR_ITEM_3_LIST_3: 'Moderate or severe frailty.',
      WHO_IS_NOT_FOR_ITEM_3_LIST_4: 'Severe depression or any condition that requires antipsychotic medication.',
      WHO_IS_NOT_FOR_ITEM_3_LIST_5: 'A heart attack within the last 6 months.',
      WHO_IS_NOT_FOR_ITEM_3_LIST_6: 'A history of heart failure AND one of the following applies:',
      WHO_IS_NOT_FOR_ITEM_3_LIST_6_LIST_1: 'Your physical activity is markedly limited.',
      WHO_IS_NOT_FOR_ITEM_3_LIST_6_LIST_2: 'You are comfortable at rest, but even minor activity causes fatigue, palpitations or breathlessness.',
      WHO_IS_NOT_FOR_ITEM_3_LIST_6_LIST_3: 'You’re not able to carry out any physical activity without discomfort. If any physical activity is undertaken, discomfort is increased.',
      WHO_IS_NOT_FOR_ITEM_3_LIST_7: 'Markedly reduced kidney function.',
      WHO_IS_NOT_FOR_ITEM_3_LIST_8: 'A recent HbA1c test result that was more than 90mmol/mol.',
      WHO_IS_NOT_FOR_ITEM_3_LIST_9: 'Significant changes to the back of the eye due to the effects of diabetes that require treatment but have not yet been treated.',
      WHO_IS_NOT_FOR_ITEM_3_LIST_10: 'Cancer.',
      WHO_IS_NOT_FOR_ITEM_3_END: 'This list is not exhaustive. If you believe that there is any other medical reason that you should not take part, please consult your doctor.',
      WHO_IS_NOT_FOR_ITEM_4: '3. If any of the following apply:',
      WHO_IS_NOT_FOR_ITEM_4_LIST_1: 'You are pregnant, planning to become pregnant, or are breastfeeding.',
      WHO_IS_NOT_FOR_ITEM_4_LIST_2: 'You have had bariatric surgery or are currently on a waiting list for bariatric surgery. If you’re considering coming off the waiting list Aversa may be an alternative option. Discuss this with your Bariatric care team.',
      WHO_IS_NOT_FOR_ITEM_4_LIST_3: 'You are unable to have the soy and milk-based meal replacement drinks. They are the only option we currently have available.  ',
      WHO_IS_NOT_FOR_END: 'All criteria will be checked before you\'re referred to ensure Aversa is a suitable option for you.',
    },
    PATIENT_INFORMATION_SECTION: {
      TITLE: 'Patient information',
      DESC_1: 'Changing Health and Abbott Nutrition have collaborated to carry out this service evaluation (clinical audit) to assess the effectiveness of a health pathway which uses a combination of nutritional shakes and health coaching service and platform.',
      DESC_2: 'Abbott Nutrition are sponsors of and have funded this service evaluation and have provided the nutritional shakes. Your GP has referred you to us as you had agreed to take part in this service evaluation and because of this, we will be processing your personal data to conduct this service evaluation.',
      DESC_3: 'Under the UK GDPR law both parties (Changing Health and Abbott Nutrition) are Joint Data Controllers. NHS are Independent Data Controllers.',
      DESC_4: 'The nutrition drinks provided in the Aversa programme, at no cost to you, are not commercialised in the UK, and are only available to participants during the 12 month duration of the programme.',
      CONTACT_DETAILS: 'Changing Health contact details:',
      CONTACT_DETAILS_1: 'Changing Health’s Data Protection Officer (DPO) can be contacted by email at: ',
      CONTACT_DETAILS_EMAIL: 'dpo.changinghealth@kaleidoscopeconsultants.com',
      CONTACT_DETAILS_2: 'or by phone at 020 3637 1111 ext. 203',
      DESC_5: 'Changing Health will be dealing with all data protection rights requests, as Abbott does not have access to personal data that identifies you. Please note not all rights are absolute, and the right to portability and erasure do not apply to this processing of personal data. Please contact the DPO for further information.',
      DESC_6: 'Only Changing Health authorised employees will have access to your personal data for this service evaluation. Abbott and the NHS will only receive anonymised reports.',
      DESC_7: 'Changing Health will store the data collected from and about you for a period of 7 years and will securely destroy the data at the end of this period.',
      DESC_8: 'You have the right to make a complaint to the UK’s Information Commissioners Office (ICO) at its website: ',
      DESC_8_WEB: 'https://ico.org.uk/make-a-complaint',
      DESC_8_1: 'or via telephone: 0303 123 1113.',
      DESC_9:'Changing Health cannot offer any medical advice. If you experience any adverse events or side effects, please contact your GP for further advice.',
    },
    INFO: {
      WHAT_IS: 'What is Aversa?',
      AVERSA_IS: 'You’re at the start of a 12-week learning experience – built on the collective expertise of dietitians, lifestyle coaches, physiotherapists, and mental wellbeing specialists.',
      AVERSA_IS_1: 'This experience has been carefully designed with learning at its heart to give you the tools to protect your strength, health, and mental wellbeing – so you can continue to live an independent life, long into the future.',
    },
    FORM: {
      REGISTER_FOR_THE_PROGRAMME: 'Register for the programme',
      FIRST_NAME: 'First name',
      LAST_NAME: 'Last name',
      EMAIL: 'Email',
      I_AGREE_TO: 'I agree to',
      CHANGING_HEALTH_TERMS: 'Changing Health\'s Terms',
      AND: 'and',
      AVERSA_TERMS: 'Aversa\'s service description',
      CHANGING_HEALTH: 'Changing Health\'s ',
      PRIVACY_POLICY: 'privacy policy',
      TERMS_CONDITIONS: 'terms & conditions',
      AVERSA: 'Aversa\'s ',
      SERVICE_DESCRIPTIONS: 'service descriptions',
      REGISTER: 'Register',
      BY_FILLING_FORM_YOU_ARE_CONSENTING :'* By filling this form you are consenting to share your personal information with NHS England. Your information will be held confidentially and will not be shared with any third parties. For more information review our ',
      ERROR: {
        CANT_BE_BLANK_MIN_2: "Can't be blank, is too short (minimum is 2 character)",
        TO_LONG_MAX_50: 'Is too long (maximum are 50 characters)',
        EMAIL_ADDRESS_VALID: 'Enter an email address in the correct format, like name@example.com',
        AGREE_PRIVACY_POLICY: 'You will need to agree to the privacy policy, terms and Aversa\'s service to continue with registration.',
        EMAIL_ADDRESS_IN_USE: 'The email address is already in use.',
        USE_A_DIFFERENT_ADDRESS: 'Please use a different email address, or ',
        LOGIN: 'login',
        WITH_EXISTING_ACCOUNT: ' with your existing account.',
        GENERIC: "We weren't able to process your form",
        GENERIC_DESCRIPTION: 'Please try completing and sending the form again. If you are still having problems contact us at ',
      },
    },
    ACCESS_AVAILABLE_ENGLAND: 'Access to this programme is only available in England.',
    YOUR_IP: 'Your IP: ',
    IS_LOCATED_IN: ' is located in ',
    YOURE_TRYING_PROGRAME: 'It looks like you’re trying to sign up to the Aversa programme from outside of England. ',
    IF_YOU_NEED_MORE_HELP: 'If you need more help please contact us at ',
  },
  HEADER: {
    LOGIN: 'Login',
    LOGO_AVERSA: 'Aversa logo',
    LOGO_NHS: 'Healthy NHS logo',
  },
  FOOTER: {
    COOKIE_POLICY: 'Cookie Policy',
    ACCESSIBILITY_STATEMENT: 'Accessibility Statement',
    PRIVACY_POLICY: 'Privacy policy',
    TERMS_AND_CONDITIONS: 'Terms and conditions',
  },
  REGISTERED: {
    YOU_ARE_REGISTERED_FOR_AVERSA: 'You are now registered for Aversa',
    THANK_FOR_REGISTERING: 'Thank you for registering with Aversa.',
    OUR_DELIVERY_PARTNER_CH: 'Our delivery partner Changing Health has sent you an email with a link to complete your registration and start your programme.',
    THE_EMAIL_WILL_BE_SEND: 'The email will be sent from the email address ',
    NO_REPLY_EMAIL: 'no-reply@changinghealth.com.',
    THIS_EMAIL_SOMETIMES: 'This email can sometimes end up in your spam or junk folder, so please check there if you can’t see it straightaway.',
    IF_HAVENT_HEARD: 'If you haven’t heard from us in the next 15 minutes, email ',
    FOR_MORE_HELP: ' for more help.',
  }
};

export default translations;

// Whocantakepart?
// AversaDiabetesisavailableinselectedareas.Totakepartyoumusthavereceivedan invitationfrom yourGPpractice.
// Pleasereadthroughthefolowingcriteriawhichhavebeenusedtosendinvitations. Youmaynotknowalofyourresultsortypesofmedication,butyourGPwilcheckthese beforemakingareferal.
// ereferalcriterialistedareforthesafetyofourserviceusers.PleaseonlymakeaGP appointmentforreferaltoAversaDiabetesif,tothebestofyourknowledge,youmatch thefolowingcriteria:
