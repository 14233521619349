import {
  BaseLayout, ErrorLayout,
} from 'app/shared/layouts';
import { RouteInterface } from 'app/core/interfaces';
import {
  P404Component,
  HomeView,
} from 'app/views';

const routes: Array<RouteInterface> = [
  {
    path: '/',
    exact: true,
    layout: BaseLayout,
    component: HomeView,
    // redirect: 'song',
  },
  // {
  //   path: '/song',
  //   layout: BaseLayout,
  //   children: [
  //     {
  //       path: '/',
  //       exact: true,
  //       redirect: '/list',
  //     },
  //     {
  //       path: '/list',
  //       name: 'Songs',
  //       component: SongListView,
  //     },
  //     {
  //       path: '/new',
  //       name: 'Songs',
  //       component: SongNewView,
  //     },
  //     {
  //       path: '/:id',
  //       name: 'Songs',
  //       component: SingleSongView,
  //     },
  //   ],
  // },
  {
    path: '*',
    name: 'Error 404',
    layout: ErrorLayout,
    component: P404Component,
  },
];

export default routes;
