import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import configuration from 'config';
import { Navigator } from './navigation';
import 'react-toastify/dist/ReactToastify.css';
import { apiServiceInstance } from './core/services';

const App = (): JSX.Element => {
  useEffect(() => {
    apiServiceInstance.setToken(configuration.changingHealthToken);
  }, []);

  return(
    <>
      <ToastContainer />
      <Navigator />
    </>
  );
}

export default App;
