import { translate } from 'app/core';
import { Label } from 'nhsuk-react-components';
import React from 'react';

const PatientInformationSectionComponent = (): JSX.Element => {
  const slugTranslate = 'HOME.PATIENT_INFORMATION_SECTION';
  return (
    <div className="mx-4">
      <Label id="patient-information-section" size="l" className='purple nhsuk-u-margin-top-8'>{translate(`${slugTranslate}.TITLE`)}</Label>

      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.DESC_1`)}
      </p>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.DESC_2`)}
      </p>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.DESC_3`)}
      </p>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.DESC_4`)}
      </p>

      <p className="nhsuk-body nhsuk-u-margin-bottom-3 font-bold">
        {translate(`${slugTranslate}.CONTACT_DETAILS`)}
      </p>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.CONTACT_DETAILS_1`)}
        <a href={`mailto:${translate(`${slugTranslate}.CONTACT_DETAILS_EMAIL`)}`} className='nhsuk-link'>{translate(`${slugTranslate}.CONTACT_DETAILS_EMAIL`)}</a>
        &nbsp;
        {translate(`${slugTranslate}.CONTACT_DETAILS_2`)}
      </p>

      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.DESC_5`)}
      </p>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.DESC_6`)}
      </p>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.DESC_7`)}
      </p>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.DESC_8`)}
        <a href={translate(`${slugTranslate}.DESC_8_WEB`)} className='nhsuk-link'>{translate(`${slugTranslate}.DESC_8_WEB`)}</a>
        &nbsp;
        {translate(`${slugTranslate}.DESC_8_1`)}
      </p>

      <p className="nhsuk-body nhsuk-u-margin-bottom-3 font-bold">
        {translate(`${slugTranslate}.DESC_9`)}
      </p>
    </div>
  );
};

export default PatientInformationSectionComponent;
