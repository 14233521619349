import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'app/core';
import { Label } from 'nhsuk-react-components';
import React from 'react';

const BackToTopComponent = (): JSX.Element => (
  <Label size="s" className='purple nhsuk-u-margin-bottom-6'>
    <FontAwesomeIcon
      icon={faCaretUp}
      className='nhsuk-u-margin-right-2'
      size='1x'
    />
    <a href='#questions-section'>{translate('HOME.QUESTIONS_SECTION.BACK_TO_TOP')}</a>
  </Label>
)

const QuestionsSectionComponent = (): JSX.Element => {
  const slugTranslate = 'HOME.QUESTIONS_SECTION';
  return (
    <div className="mx-4">
      <Label size="l" className='purple nhsuk-u-margin-top-8' id='questions-section'>{translate(`${slugTranslate}.TITLE`)}</Label>

      <Label size="s" className='nhsuk-u-margin-bottom-2'>
        <span className="mr-2">1.</span>
        <a href='#question-1'>{translate(`${slugTranslate}.QUESTION_1`)}</a>
      </Label>
      <Label size="s" className='nhsuk-u-margin-bottom-2'>
        <span className="mr-2">2.</span>
        <a href='#question-2'>{translate(`${slugTranslate}.QUESTION_2`)}</a>
      </Label>
      <Label size="s" className='nhsuk-u-margin-bottom-2'>
        <span className="mr-2">3.</span>
        <a href='#question-3'>{translate(`${slugTranslate}.QUESTION_3`)}</a>
      </Label>
      <Label size="s" className='nhsuk-u-margin-bottom-2'>
        <span className="mr-2">4.</span>
        <a href='#question-4'>{translate(`${slugTranslate}.QUESTION_4`)}</a>
      </Label>
      <Label size="s" className='nhsuk-u-margin-bottom-2'>
        <span className="mr-2">5.</span>
        <a href='#question-5'>{translate(`${slugTranslate}.QUESTION_5`)}</a>
      </Label>
      <Label size="s" className='nhsuk-u-margin-bottom-2'>
        <span className="mr-2">6.</span>
        <a href='#question-6'>{translate(`${slugTranslate}.QUESTION_6`)}</a>
      </Label>
      <Label size="s" className='nhsuk-u-margin-bottom-2'>
        <span className="mr-2">7.</span>
        <a href='#question-7'>{translate(`${slugTranslate}.QUESTION_7`)}</a>
      </Label>
      <Label size="s" className='nhsuk-u-margin-bottom-2'>
        <span className="mr-2">8.</span>
        <a href='#question-8'>{translate(`${slugTranslate}.QUESTION_8`)}</a>
      </Label>
      <Label size="s" className='nhsuk-u-margin-bottom-2'>
        <span className="mr-2">9.</span>
        <a href='#question-9'>{translate(`${slugTranslate}.QUESTION_9`)}</a>
      </Label>
      <Label size="s" className='nhsuk-u-margin-bottom-2'>
        <span className="mr-2">10.</span>
        <a href='#question-10'>{translate(`${slugTranslate}.QUESTION_10`)}</a>
      </Label>
      <Label size="s" className='nhsuk-u-margin-bottom-2'>
        <span className="mr-2">11.</span>
        <a href='#question-11'>{translate(`${slugTranslate}.QUESTION_11`)}</a>
      </Label>

      <Label size="s" className='purple nhsuk-u-margin-top-8 nhsuk-u-margin-bottom-1' id='question-1'>
        <span className="mr-2">1.</span>
        {translate(`${slugTranslate}.QUESTION_1`)}
      </Label>
      <section>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_1_ITEM_1`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_1_ITEM_2`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_1_ITEM_3`)}
        </p>
        <BackToTopComponent />
      </section>

      <Label size="s" className='purple nhsuk-u-margin-top-2 nhsuk-u-margin-bottom-1' id='question-2'>
        <span className="mr-2">2.</span>
        {translate(`${slugTranslate}.QUESTION_2`)}
      </Label>
      <section>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_2_ITEM_1`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_2_ITEM_2`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_2_ITEM_3`)}
        </p>
          
        <BackToTopComponent />
      </section>

      <Label size="s" className='purple nhsuk-u-margin-top-2 nhsuk-u-margin-bottom-1' id='question-3'>
        <span className="mr-2">3.</span>
        {translate(`${slugTranslate}.QUESTION_3`)}
      </Label>
      <section>
        <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
          <li>{translate(`${slugTranslate}.QUESTION_3_LIST_1`)}</li>
          <li>{translate(`${slugTranslate}.QUESTION_3_LIST_2`)}</li>
          <li>{translate(`${slugTranslate}.QUESTION_3_LIST_3`)}</li>
        </ul>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_3_ITEM_1`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_3_ITEM_2`)}
        </p>
        <BackToTopComponent />
      </section>


      <Label size="s" className='purple nhsuk-u-margin-top-2 nhsuk-u-margin-bottom-1' id='question-4'>
        <span className="mr-2">4.</span>
        {translate(`${slugTranslate}.QUESTION_4`)}
      </Label>
      <section>
        <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
          <li>{translate(`${slugTranslate}.QUESTION_4_LIST_1`)}</li>
          <li>{translate(`${slugTranslate}.QUESTION_4_LIST_2`)}</li>
          <li>{translate(`${slugTranslate}.QUESTION_4_LIST_3`)}</li>
          <li>{translate(`${slugTranslate}.QUESTION_4_LIST_4`)}</li>
          <li>{translate(`${slugTranslate}.QUESTION_4_LIST_5`)}</li>
          <li>{translate(`${slugTranslate}.QUESTION_4_LIST_6`)}</li>
        </ul>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_4_ITEM_1`)}
        </p>
        <BackToTopComponent />
      </section>

      <Label size="s" className='purple nhsuk-u-margin-top-2 nhsuk-u-margin-bottom-1' id='question-5'>
        <span className="mr-2">5.</span>
        {translate(`${slugTranslate}.QUESTION_5`)}
      </Label>
      <section>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_5_ITEM_1`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_5_ITEM_2`)}
        </p>
        <BackToTopComponent />
      </section>

      <Label size="s" className='purple nhsuk-u-margin-top-2 nhsuk-u-margin-bottom-1' id='question-6'>
        <span className="mr-2">6.</span>
        {translate(`${slugTranslate}.QUESTION_6`)}
      </Label>
      <section>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_6_ITEM_1`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_6_ITEM_2`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          <span className="nhsuk-u-font-weight-bold">{translate(`${slugTranslate}.QUESTION_6_ITEM_3_A`)}</span>
          &nbsp;
          <span>{translate(`${slugTranslate}.QUESTION_6_ITEM_3_B`)}</span>
        </p>
        <BackToTopComponent />
      </section>

      <Label size="s" className='purple nhsuk-u-margin-top-2 nhsuk-u-margin-bottom-1' id='question-7'>
        <span className="mr-2">7.</span>
        {translate(`${slugTranslate}.QUESTION_7`)}
      </Label>
      <section>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3 font-bold">
          {translate(`${slugTranslate}.QUESTION_7_ITEM_1`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_7_ITEM_2`)}
        </p>
        <BackToTopComponent />
      </section>
        
      <Label size="s" className='purple nhsuk-u-margin-top-2 nhsuk-u-margin-bottom-1' id='question-8'>
        <span className="mr-2">8.</span>
        {translate(`${slugTranslate}.QUESTION_8`)}
      </Label>
      <section>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_8_ITEM_1`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_8_ITEM_2`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_8_ITEM_3`)}
        </p>
        <BackToTopComponent />
      </section>

      <Label size="s" className='purple nhsuk-u-margin-top-2 nhsuk-u-margin-bottom-1' id='question-9'>
        <span className="mr-2">9.</span>
        {translate(`${slugTranslate}.QUESTION_9`)}
      </Label>
      <section>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_9_ITEM_1`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_9_ITEM_2`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_9_ITEM_3`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_9_ITEM_4`)}
        </p>
        <BackToTopComponent />
      </section>

      <Label size="s" className='purple nhsuk-u-margin-top-2 nhsuk-u-margin-bottom-1' id='question-10'>
        <span className="mr-2">10.</span>
        {translate(`${slugTranslate}.QUESTION_10`)}
      </Label>
      <section>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_10_ITEM_1`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_10_ITEM_2`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_10_ITEM_3`)}
        </p>
        <BackToTopComponent />
      </section>

      <Label size="s" className='purple nhsuk-u-margin-top-2 nhsuk-u-margin-bottom-1' id='question-11'>
        <span className="mr-2">11.</span>
        {translate(`${slugTranslate}.QUESTION_11`)}
      </Label>
      <section>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_11_ITEM_1`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_11_ITEM_2`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.QUESTION_11_ITEM_3`)}
        </p>
        <BackToTopComponent />
      </section>

      <Label size="m" className='purple nhsuk-u-margin-top-8'>
        {translate(`${slugTranslate}.WHAT_SHOULD_DO_NEXT`)}
      </Label>
      <p className="nhsuk-body nhsuk-u-margin-bottom-3">
        {translate(`${slugTranslate}.WHAT_SHOULD_DO_NEXT_START`)}
      </p>

      <section className="nhsuk-u-margin-left-3">

        <Label size="s" className='purple nhsuk-u-margin-top-2 nhsuk-u-margin-bottom-1'>
          <span className="mr-2">1.</span>
          {translate(`${slugTranslate}.WHAT_SHOULD_DO_NEXT_ITEM_1`)}
        </Label>
        <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
          <li>{translate(`${slugTranslate}.WHAT_SHOULD_DO_NEXT_ITEM_1_LIST_1`)}</li>
          <li>{translate(`${slugTranslate}.WHAT_SHOULD_DO_NEXT_ITEM_1_LIST_2`)}</li>
          <li>{translate(`${slugTranslate}.WHAT_SHOULD_DO_NEXT_ITEM_1_LIST_3`)}</li>
        </ul>

        <Label size="s" className='purple nhsuk-u-margin-top-2 nhsuk-u-margin-bottom-1'>
          <span className="mr-2">2.</span>
          {translate(`${slugTranslate}.WHAT_SHOULD_DO_NEXT_ITEM_2`)}
        </Label>
        <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
          <li>{translate(`${slugTranslate}.WHAT_SHOULD_DO_NEXT_ITEM_2_LIST_1`)}</li>
          <li>
            <span>{translate(`${slugTranslate}.WHAT_SHOULD_DO_NEXT_ITEM_2_LIST_2`)}</span>
            &nbsp;
            <a href={`mailto:${translate(`EMAIL_AVERSA`)}`} className='nhsuk-link purple'>{translate(`EMAIL_AVERSA`)}</a>
            .
          </li>
        </ul>

        <Label size="s" className='purple nhsuk-u-margin-top-2 nhsuk-u-margin-bottom-1'>
          <span className="mr-2">3.</span>
          {translate(`${slugTranslate}.WHAT_SHOULD_DO_NEXT_ITEM_3`)}
        </Label>
        <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
          <li>
            {translate(`${slugTranslate}.WHAT_SHOULD_DO_NEXT_ITEM_3_LIST_1`)}
            <ul className='nhsuk-list--bullet nhsuk-u-margin-bottom-3 nhsuk-u-margin-left-3'>
              <li>{translate(`${slugTranslate}.WHAT_SHOULD_DO_NEXT_ITEM_3_LIST_1_LIST_1`)}</li>
              <li>{translate(`${slugTranslate}.WHAT_SHOULD_DO_NEXT_ITEM_3_LIST_1_LIST_2`)}</li>
              <li>{translate(`${slugTranslate}.WHAT_SHOULD_DO_NEXT_ITEM_3_LIST_1_LIST_3`)}</li>
            </ul>
          </li>
        </ul>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.WHAT_SHOULD_DO_NEXT_ITEM_4`)}
        </p>
        <p className="nhsuk-body nhsuk-u-margin-bottom-3">
          {translate(`${slugTranslate}.WHAT_SHOULD_DO_NEXT_ITEM_5`)}
        </p>

      </section>
    </div>
  );
};

export default QuestionsSectionComponent;
