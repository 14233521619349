import { translate } from 'app/core';
import React from 'react';
import { Footer as FooterNhs } from 'nhsuk-react-components';

import './Footer.component.scss';

function Footer(): JSX.Element {
  return (
    <FooterNhs className="bg-white">
      <FooterNhs.List>
        {/* https://www.changinghealth.com/policies/privacy-policy/  */}
        <FooterNhs.ListItem href="https://www.iubenda.com/privacy-policy/85853082" target="_blank">{translate('FOOTER.PRIVACY_POLICY')}</FooterNhs.ListItem>
        <FooterNhs.ListItem href="https://www.changinghealth.com/policies/cookie-policy/" target="_blank">{translate('FOOTER.COOKIE_POLICY')}</FooterNhs.ListItem>
      </FooterNhs.List>
    </FooterNhs>
  );
}

export default Footer;
