import React from 'react';

import './Header.component.scss';
import { translate } from 'app/core';

import logoAversa from 'assets/images/aversa-logo.jpeg'

const Header = (): JSX.Element => (
  <header className="bg-white lg:h-24 mb-4 lg:mb-8 shadow header">
    <div className="mx-auto flex flex-wrap md\:flex-no-wrap lg:flex-row w-full lg:w-9/12 lg:h-24 items-center lg:max-w-5xl p-0">
      <img className="ml-2 lg:ml-1 h-20 lg:h-24 lg:py-1" src={logoAversa} alt={translate('HEADER.LOGO_AVERSA')} />
    </div>
  </header>
);

export default Header;
