import React from 'react';
import './Home.component.scss';
import { translate } from 'app/core';
import { Label, Row } from 'nhsuk-react-components';
import icsLogo from 'assets/images/icb-logo.jpg';
import { ButtonLoginComponent } from 'app/shared/components';
import QuestionsSectionComponent from './QuestionsSection/QuestionsSection';
import InformationSectionComponent from './InformationSection/InformationSection';
import WhoProgrammeForSection from "./WhoProgrammeForSection/WhoProgrammeForSection";
import PatientInformationSectionComponent from './PatientInformationSection/PatientInformationSection';


const HomeComponent = (): JSX.Element => {
  
  return (
    <div className="nhsuk-u-margin-bottom-4">
      <div className="bg-white w-full mx-auto p-4 lg:p-8 rounded shadow-xl lg:max-w-5xl">
        <Label size="l" className='purple'>{translate('HOME.TITLE')}</Label>
        <Row className='container-ics-logo nhsuk-u-margin-bottom-4 align-top'>
          <img className="nhsuk-u-margin-right-3 align-top" src={icsLogo} alt={translate('HEADER.LOGO_AVERSA')} />
        </Row>
        <div>
          {/* className="ml-2 lg:ml-1 h-20 lg:h-24 lg:py-1" */}
        </div>
        <div>
          <p className="nhsuk-body nhsuk-u-margin-bottom-3">
            {translate('HOME.DESCRIPTION')}
          </p>
          <ul className='nhsuk-list--bullet'>
            <li>{translate('HOME.DESCRIPTION_LIST_1')}</li>
            <li>{translate('HOME.DESCRIPTION_LIST_2')}</li>
            <li>{translate('HOME.DESCRIPTION_LIST_3')}</li>
          </ul>
        </div>

        <div className="mx-auto bck-turquoise rounded px-8 pt-6 pb-4 mt-8 mb-2 text-left">
          <Label size="s" className='nhsuk-u-margin-bottom-2'>
            <span className="mr-2">-</span>
            <a href='#information-section'>{translate('HOME.INFORMATION_SECTION.TITLE')}</a>
          </Label>
          <Label size="s" className='nhsuk-u-margin-bottom-2'>
            <span className="mr-2">-</span>
            <a href='#who-programme-for-section'>{translate('HOME.WHO_PROGRAMME_FOR.TITLE')}</a>
          </Label>
          <Label size="s" className='nhsuk-u-margin-bottom-2'>
            <span className="mr-2">-</span>
            <a href='#questions-section'>{translate('HOME.QUESTIONS_SECTION.TITLE')}</a>
          </Label>
          <Label size="s" className='nhsuk-u-margin-bottom-2'>
            <span className="mr-2">-</span>
            <a href='#patient-information-section'>{translate('HOME.PATIENT_INFORMATION_SECTION.TITLE')}</a>
          </Label>
        </div>

        <InformationSectionComponent />
        <WhoProgrammeForSection />
        <QuestionsSectionComponent />
        <PatientInformationSectionComponent />


        <div className="mx-auto bg-gray-200 shadow-md rounded px-8 pt-6 pb-8 mt-8 mb-2">
          <Label size="m" className="purple">{translate('HOME.ALREADY_REGISTERED')}</Label>

          <section>
            <p className="nhsuk-body nhsuk-u-margin-bottom-3">
              {translate('HOME.LOGIN_TO_AVERSA')}
            </p>
            <p className="nhsuk-body nhsuk-u-margin-bottom-3">
              {translate('HOME.YOU_WILL_NEED')}
            </p>
          </section>

          <div className="nhsuk-u-margin-top-6 text-center lg:text-left">
            <div className="nhsuk-u-margin-top-6 text-center lg:text-left">
              <ButtonLoginComponent />
            </div>
          </div>

          <Label size="m" className="purple">{translate('HOME.WANT_TO_REGISTER')}</Label>

          <section>
            <p className="nhsuk-body nhsuk-u-margin-bottom-3">
              {translate('HOME.WANT_TO_REGISTER_CONTACT_GP')}
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
