import { Button } from 'nhsuk-react-components';
import React from 'react';

interface ButtonPropsI {
  text: string;
  onClick?: CallableFunction;
  className?: string;
}

const ButtonComponent = (props: ButtonPropsI): JSX.Element => {
  const { text, onClick, className } = props;
  return (
    <Button
      type="button"
      className={className}
      // className="bg-violet-500 hover:bg-violet-600 text-white px-5 lg:px-8 py-4 lg:py-2 border rounded-full uppercase font-bold text-3xl lg:text-sm"
      onClick={(): void => onClick ? onClick(): null}
    >
      {text}
    </Button>
  );
};

export default ButtonComponent;
